import React from "react"
import { graphql, StaticQuery } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HeroBanner from "../components/hero-banner"
import Slideshow10k from "../components/slideshow-10k"
import Img from "gatsby-image"
import { FaExternalLinkAlt } from "react-icons/fa"

export default ({ data }) => (
  <>
    <Layout selectedLink="7" footerFacebookLink="https://www.facebook.com/traleemarathonkerry/">
      <SEO title="Tralee 10 Mile Road Race 2024" description="Join us 18 February 2024 for our 10K road race" />
      <HeroBanner
        bgImage={data.image1.childImageSharp.fluid}
        title="Tralee 10 Mile Road Race 2024"
        subTitle="18 February 2024"
        linkTitle="Register Today!"
        link="https://eventmaster.ie/event/8BAeH7qTV1"
      />
      <section className="main-introduction">
        <div className="container-width">
          <div className="readable-width">
            <p class="intro">The Tralee Valentines 10 mile run started in 2014 and is back by popular demand for the second year. The original course will again be used; no need to fix what isn't broken!</p>
          </div>
          <div className="col-2">
            <div className="fb-iframe">
              <iframe class="fb-iframe__iframe" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftraleemarathonkerry%2F&tabs=timeline&width=400&height=500&small_header=true&adapt_container_width=true&lazy=true&hide_cover=true&show_facepile=true&appId" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <h3>Tralee 10 Mile Road Race FAQ</h3>
        <div className="container-width">
          <div className="col-1">
            <h4>Is the race chip timed?</h4>
            <p>Yes, the Tralee Marathon is timed by <a href="http://www.timingsolutionsireland.com/">Timing Solutions Ireland</a>.</p>
          </div>
          <div className="col-2">
            <h4>Where can I collect my number and race pack?</h4>
            <p>
              There will be a number collection the night before. Details will be emailed the week before the run.
            </p>
          </div>
          <div className="col-1">
            <h4>Do you have a bag drop Facility?</h4>
            <p>
              Yes, collection at the start line and collection at the finish line.
            </p>
          </div>
          <div className="col-2">
            <h4>Where do the races start and finish?</h4>
            <p>The start and finish of the 10 Mile is shown on the map below.</p>
          </div>
          <div className="col-1">
          <h4>What time does the race start?</h4>
            <p>9:00 am.</p>
          </div>          
          <div className="col-1">
          <h4>What is the cost?</h4>
            <p>€35 per participant
            <br></br><small><em>* Additional bundle offers available at registration</em></small>
            </p>
          </div>
        </div>
      </section>

      {/* <section className="course">
        <h3>Tralee 10 Mile Road Race 2024 Course</h3>
        <div className="button-subtle"><a href="https://youtu.be/TgDc0kojREA" target="_blank">View Course Flythrough Video <FaExternalLinkAlt /></a></div>
        <figure>
          <div className="course__photo-holder">
            <a
              href="https://www.mapmyrun.com/routes/view/2565734848?host_canon=mapmyrun.com"
              class="course__full-map-btn" target="_blank">
              View Interactive Map
              <FaExternalLinkAlt />
            </a>
            <Img
              className="course__photo"
              fluid={data.image3.childImageSharp.fluid}
              alt="Tralee 10K 2024 Course"
            />
          </div>
        </figure>
      </section> */}

      {/* <section className="regulations">
        <h3>Rules & Regulations</h3>
        <p><span>All rules have been established in order to protect the safety of our participant's.</span><span>Your cooperation is greatly appreciated in helping everyone to enjoy race day.</span></p>
        <div className="regulations__bullets">
          <ol>
            <li>Entry fees are non-refundable and non-transferable.</li>
            <li>
              Headphones are not permitted on the course for reasons of health &
              safety, both yours and other competitors. When you wear such a
              device you are unable to hear other competitors, cars, bicycles or
              stewards instructions.
            </li>
            <li>All runners must complete the course in 3 hours.</li>
            <li>
              There are no road closures normal rules of the road apply, runners
              are requested to wear a High Viz vest, please follow any
              instructions from the stewards and Gardaí.
            </li>
            <li>Please be courteous to other road and pavement users.</li>
            <li>
              If you have to drop out, you should contact the race director.
            </li>
            <li>
              Event officials reserve the right, to modify, supplement or waive
              all or part of the event rules and decisions of the race director
              are final in all matters.
            </li>
            <li>
              Water and refreshments may be available around the course but
              overall runners and their crew are responsible for catering to
              their own needs.
            </li>
            <li>
              This is a Leave No Trace event please dispose of litter properly.
            </li>
            <li>
              If a runner is requested to retire from the event by medical staff
              they must do so.
            </li>
          </ol>
        </div>
      </section> */}

      {/* <section className="photo-gallery">
        <h3>Photo Gallery</h3>
        <Slideshow10k />
      </section> */}
    </Layout>
  </>
)

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "10k-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "logo-tralee-summer-solstice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "10k-course.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 95
          srcSetBreakpoints: [960, 960, 960, 1280, 1920]
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
